import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/starswap-front-home/starswap-front-home/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Info = makeShortcode("Info");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Info mdxType="Info">
  Starswap V1, while still full-featured, is no longer under active development. Looking for <Link style={{
        display: "contents"
      }} to='/docs/v2/' mdxType="Link">V2 documentation</Link>?
    </Info>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://github.com/Starswap/Starswap-sdk/tree/v1"
      }}>{`Starswap SDK`}</a>{` is meant to simplify every aspect of integrating Starswap into your project. It’s written in `}<a parentName="p" {...{
        "href": "https://www.typescriptlang.org"
      }}>{`TypeScript`}</a>{`, has a `}<a parentName="p" {...{
        "href": "https://github.com/Starswap/Starswap-sdk/tree/v1/src/__tests__"
      }}>{`robust test suite`}</a>{`, uses `}<a parentName="p" {...{
        "href": "https://github.com/MikeMcl/bignumber.js"
      }}>{`bignumber.js`}</a>{` for math, and includes an optional data-fetching module which relies on `}<a parentName="p" {...{
        "href": "https://github.com/ethers-io/ethers.js/"
      }}>{`ethers.js`}</a>{`.`}</p>
    <p>{`The SDK was built to be extremely easy to use, but also feature-rich. It offers various levels of abstraction that make it suitable for use nearly anywhere, from hackathon projects to production applications.`}</p>
    <h1 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h1>
    <p>{`The SDK is divided into several modular components that perform tightly scoped tasks:`}</p>
    <ul>
      <li parentName="ul"><Link to='/docs/v1/SDK/data' mdxType="Link">Data</Link> - Fetches Starswap data from the blockchain</li>
      <li parentName="ul"><Link to='/docs/v1/SDK/computation' mdxType="Link">Computation</Link> - Computes market- and trade-specific statistics using blockchain data</li>
      <li parentName="ul"><Link to='/docs/v1/SDK/format' mdxType="Link">Format</Link> - Formats data for display</li>
      <li parentName="ul"><Link to='/docs/v1/SDK/orchestration' mdxType="Link">Orchestration</Link> - Offers named abstraction functions that seamlessly combine lower-level data- and computation-related functions</li>
      <li parentName="ul"><Link to='/docs/v1/SDK/transact' mdxType="Link">Transact</Link> - Prepares computed trades for execution against Starswap smart contracts</li>
      <li parentName="ul"><Link to='/docs/v1/SDK/constants' mdxType="Link">Constants</Link> - Exports various helpful constants for use throughout the SDK</li>
    </ul>
    <p>{`Additionally, it exports a number of custom types:`}</p>
    <ul>
      <li parentName="ul"><Link to='/docs/v1/SDK/types' mdxType="Link">Types</Link> - Exports all types used by the SDK</li>
    </ul>
    <h1 {...{
      "id": "installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#installation",
        "aria-label": "installation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installation`}</h1>
    <p>{`To start using the SDK, simply install it into your project…`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn add @Starswap/sdk
`}</code></pre>
    <p>{`…import some functions…`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { ... } from '@Starswap/sdk'
`}</code></pre>
    <p>{`…and dive into the rest of the documentation to learn more!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      